import Btn from "../Button";

const ErrorBoundary = () => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center">
      <h2>Something went wrong while loading this page.</h2>
      <Btn onClick={() => window.location.reload()}>Reload</Btn>
    </div>
  );
};

export default ErrorBoundary;
